<template>
  <v-container fluid tag="section">
    <v-row v-if="!loading" justify="center">
      <v-col cols="12" md="10">
        <base-material-card color="indigo">
          <template v-slot:heading>
            <div class="text-h3 font-weight-light" color="white">
              {{ $t('devices') }}
            </div>
          </template>
        </base-material-card>
        <v-row class="align-baseline pa-4">
          <!-- <div class="mx-5">
            הוספת מכשיר:
          </div> -->
          <div>
            <v-autocomplete
              v-model="addDevices"
              :items="availableDevices"
              :filter="customFilter"
              :disabled="loadingForm"
              label="מכשיר"
              clearable
              dense
              persistent-hint
              solo
            >
              <template v-slot:selection="data">
                <span class="text-h5 font-weight-bold purple--text">
                  {{ data.item.identifier }}
                </span>
              </template>
              <template v-slot:item="data">
                <v-row class="justify-space-between">
                  <div>
                    <span class="text-h5 font-weight-bold purple--text">
                      {{ data.item.identifier }}
                    </span>
                  </div>
                  <div>
                    <sensors-icons :type="data.item.sensor_type" />
                  </div>
                </v-row>
              </template>
            </v-autocomplete>
          </div>
          <v-btn color="success" dark class="mx-4" @click="addDevice">
            הוספת מכשיר
          </v-btn>
        </v-row>

        <v-form ref="form">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th />
                  <th>מזהה מכשיר</th>
                  <th>חיישנים</th>
                  <th>שם</th>
                  <th>נראות</th>
                  <th>מחיקה</th>
                </tr>
              </thead>
              <draggable v-model="form" tag="tbody">
                <tr
                  v-for="device in form"
                  :key="device.device"
                  :class="device.deleted ? 'red lighten-5' : ''"
                >
                  <td class="draggable">
                    <v-icon v-text="'mdi-drag-variant'" />
                  </td>
                  <td scope="row">
                    <span class="text-h5 font-weight-bold purple--text">
                      {{ device.device }}
                    </span>
                  </td>
                  <td>
                    <sensors-icons :type="device.sensor_type" />
                  </td>
                  <td>
                    <base-smart-input
                      :model.sync="device.name"
                      label="שם מכשיר"
                      min="2"
                      max="25"
                      counter
                      required
                      :disabled="device.deleted || loadingForm"
                    />
                  </td>
                  <td>
                    <v-checkbox
                      v-model="device.visibility"
                      :on-icon="'mdi-eye'"
                      :off-icon="'mdi-eye-off-outline'"
                      :disabled="device.deleted || loadingForm"
                    />
                  </td>
                  <td>
                    <v-checkbox
                      :on-icon="'mdi-trash-can'"
                      :off-icon="'mdi-trash-can-outline'"
                      color="red darken-1"
                      :disabled="device.deleted || loadingForm"
                      @click="deleteDevice(device)"
                    />
                  </td>
                </tr>
              </draggable>
            </template>
          </v-simple-table>
        </v-form>
      </v-col>
    </v-row>
    <v-row v-if="!loading" class="justify-center mt-8">
      <v-col cols="6">
        <v-btn
          color="success"
          :disabled="!form.length"
          :loading="loadingForm"
          class="mx-4"
          block
          @click="save"
        >
          שמירה
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-else class="justify-center">
      <v-progress-circular
        :size="150"
        :width="7"
        color="primary"
        indeterminate
      />
    </v-row>
  </v-container>
</template>

<script>
import draggable from 'vuedraggable'
import SensorsIcons from '@/views/dashboard/components/SensorsIcons'
export default {
  name: 'UserDetails',
  components: {
    draggable,
    SensorsIcons,
  },
  data: () => ({
    form: [],
    addDevices: [],
    deviceName: '',
    loadingForm: false,
  }),
  computed: {
    loading () {
      const devices = this.$store.getters['usersDevices/loading']
      const userDevices = this.$store.getters['devices/loading']
      return devices && userDevices
    },
    userDevices () {
      // const email = this.$route.params.slug
      // .replace('---', '@')
      // .replaceAll('~', '.')
      const email = this.$route.params.slug.split('~').join('.')
      let devices = this.$store.getters['usersDevices/getUser'](email)
      if (devices && devices.length) {
        devices = [...devices].map(d => {
          const device = this.$store.getters['devices/device'](d.device)
          if (device && device.settings) {
            d.sensor_type = device.settings.sensor_type
          } else {
            d.sensor_type = 0
          }
          d.deleted = false
          return d
        })
        return devices
      } else {
        return []
      }
    },
    availableDevices () {
      const form_devices = this.form.map(d => d.device)
      const devices = []
      const all_devices = this.$store.getters['devices/devices']
      for (const identifier in all_devices) {
        if (!form_devices.includes(identifier)) {
          const device = { identifier: identifier }
          if (all_devices[identifier].settings) {
            device.sensor_type = all_devices[identifier].settings.sensor_type
          }
          devices.push(device)
        }
      }
      return devices
    },
  },
  watch: {
    userDevices () {
      this.setForm()
    },
  },
  mounted () {
    this.setForm()
    // console.log('this.form', this.form)
    // console.log('devices', this.devices)
  },
  methods: {
    setForm (parse = false) {
      if (parse || this.form.length === 0) {
        let form = JSON.parse(JSON.stringify(this.userDevices))
        if (form.length) {
          form = form.sort(
            (a, b) => parseInt(a.view_order) - parseInt(b.view_order),
          )
        }
        this.form = form
      }
    },
    customFilter (item, queryText, itemText) {
      const search = queryText.toLowerCase()
      const identifier = item.identifier.toLowerCase()
      return identifier.includes(search)
    },
    addDevice () {
      // console.log(this.form)
      this.form.push({
        device: this.addDevices.identifier,
        name: '',
        view_order: null,
        visibility: true,
        sensor_type: this.addDevices.sensor_type,
      })
      // console.log(this.addDevices)
    },
    deleteDevice (device) {
      if ('deleted' in device) {
        device.deleted = !device.deleted
      } else {
        this.form = this.form.filter(d => d.device !== device.device)
      }
    },
    async save () {
      if (this.$refs.form.validate()) {
        this.loadingForm = true
        this.setOrder()
        const devices = this.form.filter(d => !d.deleted)
        await this.$store.dispatch('usersDevices/editUser', {
          slug: this.$route.params.slug,
          devices: devices,
        })
        this.setForm(true)
        this.loadingForm = false
      }
    },
    setOrder () {
      for (let i = 0; i < this.form.length; i++) {
        this.form[i].view_order = i + 1
      }
    },
  },
}
</script>
